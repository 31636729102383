import camelcaseKeys from "camelcase-keys";
import { IApiContract } from "./api-contract.type";
import { ICampaign } from "../../domain/campaign";
import { match } from "ts-pattern";

const channelHandler = (
  channel: IApiContract["channel"]
): ICampaign["channel"] => {
  return match<IApiContract["channel"], ICampaign["channel"]>(channel)
    .with("email", () => "email")
    .with("whatsapp", () => "whatsapp")
    .otherwise(() => "sms");
};

const stateHandler = (state: IApiContract["state"]): ICampaign["state"] => {
  return match<IApiContract["state"], ICampaign["state"]>(state)
    .with("sent", () => "sent")
    .with("failed", () => "failed")
    .with("scheduled", () => "scheduled")
    .otherwise(() => "draft");
};

export const responseTransformer = (resource: IApiContract): ICampaign => {
  return {
    ...camelcaseKeys(resource, { deep: true }),
    channel: channelHandler(resource.channel),
    state: stateHandler(resource.state),
    createdAt: new Date(resource.created_at),
    scheduledAt: resource.scheduled_at
      ? new Date(resource.scheduled_at)
      : undefined,
    amountDelivered: resource.amount_delivered ?? undefined,
    senderId: resource.sender_id ?? undefined,
    content: resource.content ?? undefined,
    title: resource.title ?? undefined,
  };
};
