type IErrorCode = "unknown";

export class CampaignRepoError extends Error {
  public readonly code: IErrorCode;
  public readonly cause?: unknown;
  public readonly repo: string;

  constructor(
    repo: string,
    message: string,
    code: IErrorCode,
    cause?: unknown
  ) {
    super(message);

    this.name = "CampaignRepoError";
    this.repo = repo;
    this.message = message;
    this.code = code;
    this.cause = cause;
  }
}
