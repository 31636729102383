






import { bemBuilder } from "@chatfood/core-utils";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { t } from "@/i18n";
import { IState } from "../../domain/state";
import { match } from "ts-pattern";

const css = bemBuilder("campaign-state-label");

export default defineComponent({
  name: "CampaignStateLabel",
  props: {
    state: {
      type: String as PropType<IState>,
      required: true,
    },
  },
  setup(props) {
    const label = computed(() => {
      return match<IState, string>(props.state)
        .with("failed", () => t("module.campaign.state_label.failed"))
        .with("scheduled", () => t("module.campaign.state_label.scheduled"))
        .with("sent", () => t("module.campaign.state_label.sent"))
        .otherwise(() => t("module.campaign.state_label.draft"));
    });

    return {
      label,
      css,
    };
  },
});
