import {
  fetcher,
  FetcherConnectionLostError,
  FetcherHTTPError,
} from "@/core/fetcher";
import { report } from "@chatfood/bug-reporter";
import { responseTransformer } from "./response-transformer";
import type { IApiContract } from "./api-contract.type";
import type { ISearchParams } from "./search-params.type";
import type { IRequest } from "./request.type";
import { CampaignRepoError } from "../repo-error";
import { match } from "ts-pattern";
import { globalConfig } from "@/v2/core";
import {
  apiPaginationTransformer,
  IDomainApiPaginatedResponse,
  IDomainPaginatedResourceList,
} from "@/module/common";
import { ICampaign } from "../../domain/campaign";

const sortKeyHandler = (
  sortBy: IRequest["orderBy"]
): ISearchParams["order_by"] => {
  return match<IRequest["orderBy"], ISearchParams["order_by"]>(sortBy)
    .with("title", () => "title")
    .with("scheduledAt", () => "scheduled_at")
    .with("createdAt", () => "created_at")
    .with("audienceSize", () => "audience_size")
    .with("totalCost", () => "total_cost")
    .with("amountDelivered", () => "amount_delivered")
    .with("state", () => "state")
    .with("-title", () => "-title")
    .with("-scheduledAt", () => "-scheduled_at")
    .with("-createdAt", () => "-created_at")
    .with("-audienceSize", () => "-audience_size")
    .with("-totalCost", () => "-total_cost")
    .with("-amountDelivered", () => "-amount_delivered")
    .with("-state", () => "-state")
    .otherwise(() => "-created_at");
};

const THIRTY_SECONDS = 1000 * 30;

export const fetchCampaigns = async (
  request: IRequest
): Promise<IDomainPaginatedResourceList<ICampaign>> => {
  try {
    const res = await fetcher.get<
      IDomainApiPaginatedResponse<IApiContract>,
      ISearchParams
    >(`api/${request.businessId}/campaigns`, {
      cacheTTL: THIRTY_SECONDS,
      queryString: {
        per_page: request.perPage ?? globalConfig.defaultPaginationSize,
        page: request.page ?? 1,
        order_by: sortKeyHandler(request.orderBy),
        search: request.search ?? "",
      },
    });

    return {
      data: res.data.map((campaign) => responseTransformer(campaign)),
      pagination: apiPaginationTransformer(res.meta),
    };
  } catch (err: unknown) {
    if (!(err instanceof FetcherConnectionLostError)) {
      const responseStatusCode =
        err instanceof FetcherHTTPError ? err.status : 1000;

      report(err, {
        tags: {
          "http.response_code": responseStatusCode,
        },
        context: {
          "Serialized error": JSON.stringify(err),
        },
      });
    }

    throw new CampaignRepoError(
      "fetchCampaigns",
      "An unexpected error happened",
      "unknown",
      err
    );
  }
};
