































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import { t } from "@/i18n";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomButton,
} from "@/v2/new-design-system";

const css = bemBuilder("campaign-sms-promoter");

export default defineComponent({
  name: "CampaignSmsList",
  components: { AtomText, AtomButton },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
    };
  },
});
