




































































import { bemBuilder } from "@chatfood/core-utils";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import {
  OrgTable,
  MolPagination,
  AtomText,
  AtomDate,
  AtomDateTypeEnum,
  AtomMoney,
  MolTableActionTypeEnum,
  useOrgTable,
} from "@/v2/new-design-system";
import type { IOrgTableProps, ISortDirection } from "@/v2/new-design-system";
import { t } from "@/i18n";
import { IDomainPagination } from "@/module/common";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { CampaignSmsPromoter } from "../../component/sms-promoter";
import { CampaignStateLabel } from "../../component/state-label";
import { campaignRepo } from "../../repo";
import { ICampaign } from "../../domain/campaign";
import { IRepoFetchCampaignsRequest } from "../../repo/fetch-campaigns";
import { CampaignRouteEnum } from "../../route/campaign-route.enum";

const css = bemBuilder("campaign-sms-list");

export default defineComponent({
  name: "CampaignSmsList",
  components: {
    MolPagination,
    OrgTable,
    CampaignSmsPromoter,
    CampaignStateLabel,
    AtomText,
    AtomDate,
    AtomMoney,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const hasSenderId = ref(true);
    const isLoading = ref(false);
    const campaigns = ref<ICampaign[]>([]);
    const pagination = ref<IDomainPagination>();
    const currentSort =
      ref<IRepoFetchCampaignsRequest["orderBy"]>("-createdAt");

    const { convertDirToString } = useOrgTable();

    const onTableSort = (key: string, direction: ISortDirection) => {
      const toSortBy = convertDirToString<
        IRepoFetchCampaignsRequest["orderBy"]
      >(key, direction);

      const isSameCurrentSorting = currentSort.value === toSortBy;

      if (direction === "NONE" || isSameCurrentSorting) {
        return;
      }

      currentSort.value = toSortBy;
      fetchCampaigns(1);
    };

    const fetchCampaigns = async (page: number = 1) => {
      isLoading.value = true;

      try {
        const res = await campaignRepo.get({
          businessId: props.businessId,
          orderBy: currentSort.value,
          channel: "sms",
          page,
        });

        campaigns.value = res.data;
        pagination.value = res.pagination;
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchCampaigns(1);
    });

    const columns = computed<IOrgTableProps["columns"]>(() => ({
      title: {
        header: () => t("module.campaign.sms_list.th_title"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      scheduledAt: {
        header: () => t("module.campaign.sms_list.th_scheduled_at"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      audienceSize: {
        header: () => t("module.campaign.sms_list.th_audience_size"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      totalCost: {
        header: () => t("module.campaign.sms_list.th_total_cost"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      amountDelivered: {
        header: () => t("module.campaign.sms_list.th_amount_delivered"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
      state: {
        header: () => t("module.campaign.sms_list.th_state"),
        show: true,
        sortable: true,
        sortDirection: "NONE",
      },
    }));

    const addMinutes = (datetime: Date, minutes: number): Date => {
      return new Date(datetime.getTime() + minutes * 60000);
    };

    return {
      t,
      css,
      isLoading,
      campaigns,
      hasSenderId,
      columns,
      pagination,
      fetchCampaigns,
      CampaignRouteEnum,
      AtomDateTypeEnum,
      MolTableActionTypeEnum,
      addMinutes,
      onTableSort,
    };
  },
});
