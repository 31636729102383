var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css('')},[(_vm.hasSenderId)?_c('div',[_c('OrgTable',{class:_vm.css('datatable'),attrs:{"loading":_vm.isLoading,"columns":_vm.columns,"total-records":_vm.pagination ? _vm.pagination.perPage : 0,"data":_vm.campaigns,"on-sort":_vm.onTableSort,"no-records-msg":_vm.t('module.campaign.sms_list.no_records_found_message'),"data-test":"table"},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell))])]}},{key:"scheduledAt",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('i18n',{attrs:{"path":"module.campaign.sms_list.scheduled_at_display"},scopedSlots:_vm._u([{key:"date",fn:function(){return [_c('AtomDate',{attrs:{"datetime":cell,"format":"MMMM DD YYYY"}})]},proxy:true},{key:"timeRangeFrom",fn:function(){return [_c('AtomDate',{attrs:{"datetime":cell,"format":"H:mm"}})]},proxy:true},{key:"timeRangeTo",fn:function(){return [_c('AtomDate',{attrs:{"datetime":_vm.addMinutes(cell, 15),"format":"H:mm"}})]},proxy:true}],null,true)})],1)]}},{key:"audienceSize",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_vm._v(_vm._s(cell.toLocaleString()))])]}},{key:"totalCost",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('AtomMoney',{attrs:{"amount":cell.amount,"currency":cell.currency}})],1)]}},{key:"amountDelivered",fn:function(ref){
var cell = ref.cell;
var row = ref.row;
return [(row.state === 'sent')?_c('AtomText',[_vm._v(" "+_vm._s(_vm.t("module.campaign.sms_list.amount_delivered", { delivered: cell.toLocaleString(), audience: row.audienceSize.toLocaleString(), }))+" ")]):_vm._e()]}},{key:"state",fn:function(ref){
var cell = ref.cell;
return [_c('AtomText',[_c('CampaignStateLabel',{attrs:{"state":cell}})],1)]}}],null,false,4220879515)}),(_vm.pagination)?_c('MolPagination',{attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"total":_vm.pagination.total,"per-page":_vm.pagination.perPage,"on-navigate":function (page) { return _vm.fetchCampaigns(page); }}}):_vm._e()],1):_c('CampaignSmsPromoter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }